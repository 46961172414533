import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./button.less";

const SIZES = {
  SMALL: "small",
  LARGE: "large"
};

const buttonClasses = (size, secondary, children, disabled, inline) =>
  classNames({
    button: true,
    "button--large": size === SIZES.LARGE,
    "button--small": size === SIZES.SMALL,
    "button--secondary": secondary,
    "button--disabled": disabled,
    "button--multiple-children": React.Children.count(children) > 1,
    "button--inline": inline
  });

// giving button focus on click in safari, FF still no luck
const clickHandler = (e, onClickPassedIn) => {
  e.target.focus();
  if (onClickPassedIn !== null) {
    onClickPassedIn(e);
  }
};

/**
Button component that can hold text and be either button, input, link or a downloadable file
 */
const Button = ({
  onClick,
  type,
  secondary,
  size,
  disabled,
  className,
  href,
  inputLabel,
  inline,
  children
}) => (
  <>
    {type === "download" && href && (
      <a
        className={`${buttonClasses(
          size,
          secondary,
          children,
          inline
        )} ${className}`}
        href={href}
        download
      >
        {children}
      </a>
    )}
    {type === "link" && href && (
      <a
        className={`${buttonClasses(
          size,
          secondary,
          children,
          inline
        )} ${className}`}
        href={href}
      >
        {children}
      </a>
    )}
    {type === "button" && onClick && (
      <button
        onClick={e => clickHandler(e, onClick)}
        className={`${buttonClasses(
          size,
          secondary,
          children,
          inline,
          disabled
        )} ${className}`}
        type={type}
        disabled={disabled}
      >
        {children}
      </button>
    )}
    {type === "input" && onClick && inputLabel && (
      <input
        onClick={e => clickHandler(e, onClick)}
        className={`${buttonClasses(
          size,
          secondary,
          children,
          inline,
          disabled
        )} ${className}`}
        type="button"
        disabled={disabled}
        value={inputLabel}
      />
    )}
  </>
);

export default Button;

Button.propTypes = {
  /**
   Sets the type of button
   */
  type: PropTypes.oneOf(["button", "link", "download", "input"]).isRequired,
  /**
   Function that should trigger on button click, required for button&input, not links
   */
  onClick: PropTypes.func,
  /**
   Sets button to the secondary style
   */
  secondary: PropTypes.bool,
  /**
   Sets the size of the button
   */
  size: PropTypes.oneOf(["small", "large"]),
  /**
   Disables the button and changes its stiles accordingly
   */
  disabled: PropTypes.bool,
  /**
   href for link or download
   */
  href: PropTypes.string,
  /**
   Label for input-button
   */
  inputLabel: PropTypes.string,
  /**
   Extra styling for the component, gets passed straight through
   */
  className: PropTypes.string,
  /**
   Button contents
   */
  children: PropTypes.node
};

Button.defaultProps = {
  onClick: null,
  type: "button",
  primary: true,
  size: "large",
  disabled: false,
  inputLabel: null,
  href: null,
  className: "",
  inline: false
};
