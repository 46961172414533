import React from "react";
import Button from "../Button";
import "./App.less";
import "../List/list.less";
import "../../tokens/grid/grid.less";
import "../../tokens/typo/typo.less";
import "../../tokens/grid/grid.less";

import logo from "../../resources/logo/IMDiSmall.svg";

function App() {
  return (
    <div className="App">
      <div className="col--main text-align-left">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>IMDis designsystem</h1>
        <p className="h3">
          Vår visuelle identitet springer ut fra vår merkevare- og
          virksomhetsstrategi. For å sikre riktig bruk og et konsistent uttrykk,
          har vi dokumentert grunnelementer og komponenter i et designsystem
          strukturert i tre deler:
        </p>
        <div className="row">
          <div className="col--third">
            <h3>Design i IMDi</h3>
            <p>
              Vår merkevare, introduksjon til designsystemet og eksempler på
              hvordan vi bruker designet.
            </p>
          </div>
          <div className="col--third">
            <h3>Grunnelementer</h3>
            <p>Logo, farger, typografi, ikoner mm. </p>
          </div>
          <div className="col--third">
            <h3>Komponenter </h3>
            <p>
              Oppdatert og original kode, stilsett og digitale elementer som kan
              gjenbrukes og tas i bruk i frontend utvikling og design. For
              eksempel lister, knapper, skjemaelementer og bokser.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col--half">
            <Button type="link" href="/docs-only">
              <span>&nbsp;&nbsp;Gå til designsystemet&nbsp;&nbsp;</span>
            </Button>
          </div>
          <div className="col--half">
            <Button type="link" href="/docs">
              Designsystemet for utviklere
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
